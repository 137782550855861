/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "2020 was a year of recognising and working on our ignorance. Let’s bring that same energy into 2021. I have no doubt there’s people who‘ve said something listed in this post out of a place of ignorance. It’s not that they don’t care, it’s that they don’t understand. Nevertheless there are a few comments you should avoid saying to someone with anxiety, let me explain what some of them are, why they can be offensive and what to say instead."), "\n", React.createElement(_components.p, null, "Some of the ignorant things I’ve had said to me either in response to me exhibiting anxiety symptoms or voicing my concerns towards doing something:"), "\n", React.createElement(_components.p, null, "“Grow up”"), "\n", React.createElement(_components.p, null, "“Act your age”"), "\n", React.createElement(_components.p, null, "“You’re being dramatic/irrational/silly” "), "\n", React.createElement(_components.p, null, "Other unhelpful statements:"), "\n", React.createElement(_components.p, null, "“Calm down” (if it was easy to just calm down, we would have done so)"), "\n", React.createElement(_components.p, null, "“You’ll be fine” (you cannot guarantee this and we know it)"), "\n", React.createElement(_components.p, null, "“It’s not a big deal” (it is a big deal to us)"), "\n", React.createElement(_components.p, null, "“It’s only in your head” (we already know that, the problem is we can’t get it out of our head)"), "\n", React.createElement(_components.p, null, "“I know how you feel” (unless you also have anxiety too, you do not truly know how we feel)"), "\n", React.createElement(_components.p, null, "“Here we go again” (it’s a constant problem in our lives, which brings constant suffering)"), "\n", React.createElement("ins", {
    class: "adsbygoogle",
    "data-ad-layout": "in-article",
    "data-ad-format": "fluid",
    "data-ad-client": "ca-pub-1001229702754753",
    "data-ad-slot": "8446829758"
  }), "\n", React.createElement(_components.p, null, "As a sufferer, by responding to my anxiety by saying things like this (even if you have good intentions), you’re telling me: you don’t understand how I feel; you’re not trying to understand how I feel; you’re not interested in helping me. You’re minimising and trivialising my feelings and fears, which are very much real to me. This makes me feel like I can’t open up to you, be myself around you or ask you for help. I understand that it might be draining for you, but if you feel fed up imagine how fed up I feel. If I didn’t know or understand that the people saying these things to me didn’t have anxiety themselves, nor have a good understanding of mental health problems, I would also have assumed they didn’t care."), "\n", React.createElement(_components.p, null, "Instead of the above, simply listen to someone’s concerns (and I mean actively listen, not just hearing what they say - sometimes they just need to voice their thoughts/fears out loud). More helpful comments or questions could be:"), "\n", React.createElement(_components.p, null, "“What can I do to help?”"), "\n", React.createElement(_components.p, null, "“I’m proud of you for trying/opening up”"), "\n", React.createElement(_components.p, null, "“I’m here if you need me”"), "\n", React.createElement(_components.p, null, "“It will most likely go to plan but if it doesn’t you will handle it. Let’s think of a back up plan together”"), "\n", React.createElement("ins", {
    class: "adsbygoogle",
    "data-ad-layout": "in-article",
    "data-ad-format": "fluid",
    "data-ad-client": "ca-pub-1001229702754753",
    "data-ad-slot": "8446829758"
  }), "\n", React.createElement(_components.p, null, "This is going to make them feel more like you’re trying to understand and support them, even though you don’t fully understand the feelings/thoughts they have (which is understandable if you’ve never experienced symptoms of anxiety, as they can be irrational thoughts). If they want you to help, maybe you could use your more rational thinking to calm them down, gently challenge their thoughts and think of a solution to their worries. You could use a ", React.createElement(_components.a, {
    href: "https://www.getselfhelp.co.uk/docs/worrytree.pdf"
  }, "Worry Tree"), "  to do this. However, sometimes it’s more helpful to just listen, accept what they’re saying and let them know you’re there for them, rather than to challenge their thoughts."), "\n", React.createElement(_components.p, null, "If you think someone is being dramatic, irrational or ‘acting younger than their age’, question why they might be feeling/acting that way instead of immediately judging them. If your pet dog was scared of a seemingly innocent item in your home, you wouldn’t ridicule them for being nervous, and you’re never going to fully understand how they feel (because you’re not a dog), but you would still try to comfort them. You can make someone feel a whole lot better by listening to them and accepting how they feel, than if you were to dismiss their feelings (even if it’s unintentional). It may feel draining, frustrating or daunting supporting someone with mental health problems, so remember support and advice is available for friends and family of sufferers too - see ", React.createElement(_components.a, {
    href: "https://www.mind.org.uk/information-support/types-of-mental-health-problems/anxiety-and-panic-attacks/for-friends-family/"
  }, "how you can help them whilst looking after yourself"), ". "), "\n", React.createElement(_components.p, null, "Sarah from Anxious Extrovert x"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
